import React, { useEffect, useState } from 'react'
import {
  ImageClickingAnswer,
  ImageClickingTask,
  TaskType,
} from 'data-models/task-models'
import TaskTag from './TaskTag'
import {
  Flex,
  Text,
  Center,
  Image,
  VStack,
  Box,
  HStack,
  Heading
} from '@chakra-ui/react'
import infoImage from 'assets/images/information-circle.svg'
import CheckIcon from './CheckIcon'
import { apiBase } from 'services/config'

export default function ImageClickTaskCmp(props: { task: any }) {
  const isFullscreen = true

  const currentTask = props.task
  const [task, setTask] = useState(currentTask as ImageClickingTask)

  function onSelectedChange(changed: ImageClickingAnswer['id']) {
    const newOptionsState = task.answerOptions.map(ao => ({
      ...ao,
      checked: ao.id === changed ? !ao.checked : ao.checked,
    }))

    const checkImagesSelected = newOptionsState.filter(
      x => x.checked && x.type === TaskType.ImageClick,
    )
    newOptionsState.map(obj => {
      if (
        checkImagesSelected.length > 0 &&
        obj.type === TaskType.Checkbox &&
        obj.checked
      ) {
        obj.checked = false
      }
      return obj
    })
    setTask(v => ({ ...v, ...{ answerOptions: newOptionsState } }))
  }

  const onAnswerChange = (answers: ImageClickingAnswer[]) => {
    const checkCheckboxSelected = answers.filter(
      x => x.checked && x.type === TaskType.Checkbox,
    )
    answers.map(obj => {
      if (
        checkCheckboxSelected.length > 0 &&
        obj.type === TaskType.ImageClick &&
        obj.checked
      ) {
        obj.checked = false
      }
      return obj
    })

    setTask(v => ({ ...v, ...{ answerOptions: answers } }))
  }

  useEffect(() => {}, [task])

  const leftPositions = [10, 25, 40, 54, 65, 78]
  return (
     <Flex direction='column' h='full' top={10} w='100%' overflow={'scroll'}>
      <Flex
        direction='column'
        align='stretch'
        p='5'
        h='full'
        borderRadius='md'
        bg='#773FFD'
      >
        <Box>
          <TaskTag text={task.description} />
          <Heading
            as='h1'
            fontSize='xl'
            fontWeight='semibold'
            mt='3'
            lineHeight='1'
          >
            {task.title}
          </Heading>
          <Text fontWeight='semibold' mt='3'>
            {task.subtitle}
          </Text>
        </Box>

        <Flex
          h='full'
          w='full'
          maxW='full'
          justify='center'
          align='center'
          flexDirection='column'
        >
          <HStack
            spacing='5'
            justify='center'
            pt='20px'
            w={
              task.imageUrls?.length > 1 || task.videoUrls?.length > 1
                ? '100%'
                : '100%'
            }
            h={
              task.imageUrls?.length > 1 || task.videoUrls?.length > 1
                ? '50%'
                : '100%'
            }
          >
            <Box
              key={task.imageUrls[0].toString()}
              ratio={2}
              borderRadius='xl'
              p={'6.25%'}
              w='full'
              overflow='hidden'
              pos={'relative'}
            >
            <Image
                key={task.imageUrls[0]}
                src={apiBase + task.imageUrls[0]}
                borderRadius='xl'
                w='full'
              />
              {task.answerOptions.map(
                (ao, index) =>
                  ao.type === TaskType.ImageClick && (
                    <Center
                      key={ao.id}
                      boxSize='10vh'
                      borderRadius='xl'
                      borderWidth='4px'
                      mx='2'
                      my='2'
                      bottom={'15%'}
                      left={`${leftPositions[index % leftPositions.length]}%`}
                      pos='absolute'
                      cursor='pointer' 
                      bgColor={ao.checked ? 'white' : 'transparent'}
                      borderColor={ao.checked ? 'pink.500' : 'transparent'}
                      className={
                        ao.checked ? 'image image-clicked' : 'image-unselected'
                      }
                      onClick={() => onSelectedChange(ao.id)}
                    >
                      {ao.checked && (
                        <Center
                          pos='absolute'
                          left='1'
                          top='1'
                          boxSize='5'
                          bgColor='pink.500'
                          borderRadius='full'
                        >
                          <CheckIcon color='white' />
                        </Center>
                      )}
                      <Image
                        src={apiBase + ao.imageUrl}
                        borderRadius='xl'
                        maxH='70%'
                        maxW='70%'
                      />
                    </Center>
                  ),
              )}
            </Box>
          </HStack>
        </Flex>

        <VStack
          pb={isFullscreen ? '20px' : '0'}
          pt='1'
          w='full'
          align='stretch'
          borderTop='1px solid #352EB1'
        >
          <HStack lineHeight='1.5'>
            <Image src={infoImage} />
            <Text fontSize='sm'>{task.taskInstructions}</Text>
          </HStack>
        </VStack>
      </Flex>
    </Flex>
  )
}
